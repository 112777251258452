const Segment = {
  identify(id, traits) {
    analytics.identify(id, traits);
  },
  track(eventName, properties){
    analytics.track(eventName, properties);
  },
  user() {
    return analytics.user();
  },
  async ready(callback) {
    await analytics.ready(callback);
  }
};
